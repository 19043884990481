import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
import { get } from 'lodash'

// Vuex Suppliers module
const moduleSupplierUsers = {
  namespaced: true,
  state: {
    supplierUsers: [],
    selectedSupplierUser: [],
    errorMessage: null,
  },
  mutations: {
    SET_SUPPLIER_USERS(state, supplierUsers) {
      state.supplierUsers = supplierUsers
    },
    SET_SUPPLIER_USER(state, supplierUser) {
      state.selectedSupplierUser = supplierUser
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchSupplierUsers({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.supplierUsers.fetchSupplierUsers()
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_SUPPLIER_USERS', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async fetchSupplierUser({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.supplierUsers.fetchSupplierUser(payload)
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_SUPPLIER_USER', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async postSupplierUser({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.supplierUsers.postSupplierUser(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description || GENERIC_ERROR,
          }
        }
        return { isError: false }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async putSupplierUser({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.supplierUsers.putSupplierUser(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          return { isError: false }
        }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleSupplierUsers
