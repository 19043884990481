import Vue from 'vue'
import store from '@/store'
import jwt_decode from 'jwt-decode'

export default async function authGuard(to, from, next) {
  const auth = Vue.prototype.$OAuth

  if (!isAuthenticated()) {
    await auth.getAuth()
    return false
  } else if (extractProfile()) {
    store.commit('SET_AUTHENTICATED', true)
    next()
  } else {
    store.commit('SET_AUTHENTICATED', false)
    store
      .dispatch(
        'setErrorMessage',
        'You need to be member of either of these groups. IT_HUE_ICC_ADMIN or IT_HUE_ICC_READ'
      )
      .finally(() => {
        next('/error')
      })
  }
}

const isAuthenticated = () => {
  let tokenExpired = true

  const localStoreAccessToken = localStorage.getItem('access_token')
  const localStoreIdToken = localStorage.getItem('id_token')

  if (localStoreAccessToken && localStoreIdToken) {
    // check expiry
    const decodedToken = decodeToken(localStoreIdToken)

    if (decodedToken?.exp) {
      const expiryEpoch = decodedToken.exp
      if (expiryEpoch) {
        const expDate = new Date(parseFloat(expiryEpoch * 1000))
        tokenExpired = new Date() >= expDate
      }

      return !tokenExpired;
    }
  }

  return false
}

const extractProfile = () => {
  const decodedToken = decodeToken(localStorage.getItem('access_token'))
  if (decodedToken) {
    const givenName = decodedToken['given_name']
    const surname = decodedToken['family_name']
    const isAdminUser = !!decodedToken['groups'].filter(
      (gr) => gr.toUpperCase() === 'IT_HUE_ICC_ADMIN'
    ).length
    const isReadOnlyUser = !!decodedToken['groups'].filter(
      (gr) => gr.toUpperCase() === 'IT_HUE_ICC_READ'
    ).length

    if (isReadOnlyUser || isAdminUser) {
      store.dispatch('setCurrentUser', {
        givenName: givenName,
        surname: surname,
        isAdmin: isAdminUser,
        isReadOnlyUser: !isAdminUser && isReadOnlyUser,
      })

      return true
    }
  }

  return false
}

const decodeToken = (token) => {
  try {
    return jwt_decode(token)
  } catch (e) {
    return null
  }
}
