import { get, post, put } from '../http'
import { GENERIC_ERROR } from '@/constants/common'

const fetchSuppliers = () => {
  return new Promise((done, reject) => {
    get('/v1/opUI/suppliers')
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && results.data.suppliers) {
          done(results.data.suppliers)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const fetchSupplier = (supplierId) => {
  return new Promise((done, reject) => {
    get(`/v1/opUI/suppliers/${supplierId}`)
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && !results.data.supplierId) {
          done({
            isError: true,
            errorMessage: `Supplier with id ${supplierId} not found.`,
          })
        } else if (results.status === 200 && results.data.supplierId) {
          done(results.data)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
const postSupplier = (data = {}, config = {}, isForm = false) =>
  post(`/v1/opUI/admin/suppliers/supplier`, data, config, isForm)

const putSupplier = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/suppliers/${data.supplierId}`, data, config, isForm)

export default {
  fetchSuppliers,
  fetchSupplier,
  postSupplier,
  putSupplier,
}
