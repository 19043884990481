import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'

// Vuex SifObjects module
const moduleSifObjects = {
  namespaced: true,
  state: {
    sifObjects: [],
    selectedSifObject: [],
  },
  mutations: {
    SET_SIFOBJECTS(state, sifObjects) {
      state.sifObjects = sifObjects
    },
    SET_PRODUCT(state, sifObject) {
      state.selectedSifObject = sifObject
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchSifObjects({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.sifObjects.fetchSifObjects('/sifObjects')
        if (response?.isError) {
          commit('SET_ERROR_MESSAGE', response?.errorMessage)
        } else {
          commit('SET_SIFOBJECTS', response)
        }
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchSifObject({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      // Examples to use the api module:
      api.sifObjects
        .fetchSifObject('/sifObject')
        .then((response) => {
          if (response) {
            commit('SET_PRODUCT', response)
          }
        })
        .catch((error) => console.log(error)) // eslint-disable-line
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true })
          }
        })
    },
  },
}

export default moduleSifObjects
