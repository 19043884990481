import api from '../../api'

// Vuex Schools module
const moduleSchools = {
  namespaced: true,
  state: {
    schools: [],
    selectedSchool: null,
  },
  mutations: {
    SET_SCHOOLS(state, schools) {
      state.schools = schools
    },
    SET_SCHOOL(state, school) {
      state.selectedSchool = school
    },
  },
  actions: {
    fetchSchools({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      // Examples to use the api module:
      api.schools
        .fetchSchools('/schools')
        .then((response) => {
          if (response) {
            const activeSch = response.filter(
              (s) => s.stateCode === 'NSW' && s.activeInd === 'A' && s.nswGovSchool === 'Y'
            )
            const nswSchools = activeSch.map((sch) => ({
              rec: sch.uniqueRecord,
              code: sch.orgUnitCode,
              name: sch.orgUnitName,
            }))

            commit('SET_SCHOOLS', nswSchools)
          }
        })
        .catch((error) => console.log(error)) // eslint-disable-line
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true })
          }
        })
    },
  },
}

export default moduleSchools
