import Vue from 'vue'
import Vuex from 'vuex'
import moduleSuppliers from './modules/suppliers'
import moduleProducts from './modules/products'
import moduleInstances from './modules/instances'
import moduleSubscriptions from './modules/subscriptions'
import moduleSubscriptionRequests from './modules/subscriptionRequests'
import moduleServices from './modules/services'
import moduleSifObjects from './modules/sifobjects'
import moduleSchools from './modules/schools'
import moduleSupplierUsers from './modules/supplierUsers'
import authModule from './modules/auth'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    moduleSuppliers,
    moduleProducts,
    moduleInstances,
    moduleServices,
    moduleSifObjects,
    moduleSubscriptions,
    moduleSubscriptionRequests,
    moduleSchools,
    moduleSupplierUsers,
    authModule,
  },
})
