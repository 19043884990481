/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import suppliers from './modules/suppliers'
import products from './modules/products'
import instances from './modules/instances'
import subscriptions from './modules/subscriptions'
import subscriptionRequests from './modules/subscriptionRequests'
import services from './modules/services'
import sifObjects from './modules/sifobjects'
import schools from './modules/schools'
import supplierUsers from './modules/supplierUsers'

export default {
  suppliers,
  products,
  instances,
  subscriptions,
  subscriptionRequests,
  services,
  sifObjects,
  schools,
  supplierUsers
}
