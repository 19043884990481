import { post, get, put } from '../http'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'

/**
 * Fetches services based on the provided parameters.
 * @param {Object} params - The parameters for fetching services.
 * @param {number} params.limit - The number of services to fetch.
 * @param {number} params.page - The page number of services to fetch.
 * @returns {Promise<Array|Object>} - A promise that resolves to an array of services or an error object.
 */
const fetchServices = (params) => {
  return new Promise(function (done) {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) =>
          value !== undefined && (typeof value === 'string' || typeof value === 'number')
      )
    )
    const queryString = new URLSearchParams(filteredParams).toString()
    get(`/v1/opUI/admin/standardServices?${queryString}`)
      .then((results) => {
        if (results.data.services) done(results.data.services)
        else
          done({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}

const fetchService = ({ serviceId }) => {
  return new Promise(function (resolve) {
    get(`/v1/opUI/admin/standardServices/${serviceId}`)
      .then((results) => {
        if (results.data) resolve(results.data)
        else {
          resolve({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
        }
      })
      .catch((error) => {
        resolve({
          isError: true,
          errorMessage: _.get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}
// const fetchServices = (url, config = {}) => get(url, config);
const postServices = (data = {}, config = {}, isForm = false) =>
  post('/v1/opUI/admin/standardServices/standardService', data, config, isForm)

const putServices = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/standardServices/${data.id}`, data, config, isForm)

export default {
  fetchServices,
  fetchService,
  postServices,
  putServices,
}
