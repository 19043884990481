import { get, post, put } from '../http'
import { GENERIC_ERROR } from '@/constants/common'

/* eslint no-console: "off" */
const fetchProducts = () => {
  return new Promise((done, reject) => {
    get('/v1/opUI/products')
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && results.data.products) {
          done(results.data.products)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/* eslint no-console: "off" */
const fetchProduct = ({ productId, supplierId }) => {
  return new Promise((done, reject) => {
    get(`/v1/opUI/products/${productId}?supplierId=${supplierId}`)
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && !results.data.productId) {
          done({
            isError: true,
            errorMessage: `Product with id ${productId} not found.`,
          })
        } else if (results.status === 200 && results.data.productId) {
          done(results.data)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const postProduct = (data = {}, config = {}, isForm = false) =>
  post(`/v1/opUI/admin/products/product`, data, config, isForm)

const putProduct = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/products/${data.productId}`, data, config, isForm)

export default {
  fetchProducts,
  fetchProduct,
  postProduct,
  putProduct,
}
