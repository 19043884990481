let schoolsAPI = process.env.VUE_APP_REFDATA_API

import API from '@/store/apiUtils'
import router from '@/router'

export default {
  setStore(store) {
    // Sets store instance for any helpers that need it
    API.setStore(store)
  },

  set({ commit }, [key, value]) {
    // Sets a value into state
    commit('set', [key, value])
  },

  setCurrentUser({ commit }, payload) {
    commit('set', ['userName', `${payload.givenName} ${payload.surname}`])
    commit('set', ['givenName', payload.givenName])
    commit('set', ['surname', payload.surname])
    commit('set', ['userGroup', payload.groups])

    commit('set', ['isAdmin', payload.isAdmin])
    commit('set', ['isReadOnlyUser', payload.isReadOnlyUser])
  },

  setErrorMessage({ commit }, message) {
    commit('set', ['errorMessage', message])
  },

  // AJAX actions -----------------------------------------------------------------------

  // Temporary API to retrieve all NSW schools for none-school users: TO DO: combine the schools to reference data.
  // get all Australian schools.
  getAllAuSchools() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/all-schools.json`, true).then((schools) => {
        done(schools)
      })
    })
  },

  async refreshTokens() {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      const { id_token, refresh_token, access_token } =
        await this._vm.$auth.DET.refreshTokens(refreshToken)
      if (id_token) {
        localStorage.setItem('idToken', id_token)
      }
      if (access_token) {
        localStorage.setItem('accessToken', access_token)
      }
      if (refresh_token) {
        localStorage.setItem('refreshToken', refreshToken)
      }
      return !!id_token
    } else {
      throw 'No refresh token was found'
    }
  },

  logout() {
    router.push('logout')
  },

  reauthenticateUser() {
    this._vm.$auth.DET.startAuth()
  },
}
