import { put, get } from '../http'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'

/**
 * Fetches subscription requests based on the provided parameters.
 * @param {Object} params - The parameters for fetching subscription requests.
 * @param {string} params.status - The status to fetch.
 * @param {string} params.schoolCode - The school code.
 * @param {string} params.supplierId - The ID of the supplier.
 * @returns {Promise<Array|Object>} - A promise that resolves to an array of subscription requests or an error object.
 */
const fetchSubscriptionRequests = (params) => {
  return new Promise(function (resolve) {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) =>
          value !== undefined && (typeof value === 'string' || typeof value === 'number')
      )
    )
    const queryString = new URLSearchParams(filteredParams).toString()
    get(`/v1/opUI/subscriptionRequests?${queryString}`)
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          resolve({
            isError: true,
            errorMessage: results.data.error.description,
          })
        } else if (results.status === 200 && results.data) {
          resolve(results.data)
        } else {
          resolve({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch(() => {
        resolve({
          isError: true,
          errorMessage: GENERIC_ERROR,
        })
      })
  })
}

const fetchSubscriptionRequest = ({ requestId, supplierId }) => {
  return new Promise(function (resolve) {
    get(`/v1/opUI/subscriptionRequests/${requestId}?supplierId=${supplierId}`)
      .then((results) => {
        if (results.data.error) {
          resolve({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
        } else if (results.data) {
          resolve(results.data)
        } else {
          resolve({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        resolve({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}
const putSubscriptionRequests = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/subscriptionRequests/${data.subscriptionReqId}`, data, config, isForm)

/**
 * DataPutBulk object.
 * @typedef {Object} DataPutBulk
 * @property {string} status - The status to update, either 'approve' or 'reject'.
 * @property {Array} subscriptionReqs - The subscription requests to update.
 * @property {string} rejectReason - The reason for rejecting the subscription requests.
 * @example
 * {
 *  status: 'approve',
 *  subscriptionReqs: [
 *  {
 *    subscriptionReqId: '82aDEc2E-8ea8-d935-b9b0-2bb5C3C718BD',
 *    supplierId: '61aDEc2D-8ea8-d935-b9b0-1aa5C3C718BD',
 *  }
 * }
 */
/**
 * Approve or reject multiple subscription requests.
 * @param {DataPutBulk} data - The data to approve or reject.
 * @param {Object} config - The configuration object for the request.
 * @param {boolean} isForm - Indicates if the request is a form.
 * @returns {Promise} - A promise that resolves to the response of the request.
 * @example
 */
const putBulk = (data = {}, config = {}, isForm = false) => {
  const url = `/v1/opUI/admin/subscriptionRequests/bulk-update/${data.status}`
  const body = {
    subscriptionReqs: data.subscriptionReqs,
    rejectReason: data.rejectReason,
  }
  return put(url, body, config, isForm)
}

const fetchBatchDetails = (params) => {
  return new Promise(function (resolve) {
    const { batchId, batchName, supplierId, update } = params
    let url = `/v1/opUI/subscriptionRequests/bulk-update/stats/${batchId}/?batchName=${batchName}&supplierId=${supplierId}`
    if (update) url += `&update=${update}`

    get(url)
      .then((results) => {
        if (results.data) {
          resolve(results.data)
        } else {
          resolve({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
        }
      })
      .catch((error) => {
        resolve({
          isError: true,
          errorMessage: _.get(error, 'data.error.description', GENERIC_ERROR),
        })
      })
  })
}

export default {
  fetchSubscriptionRequests,
  fetchSubscriptionRequest,
  putSubscriptionRequests,
  putBulk,
  fetchBatchDetails,
}
