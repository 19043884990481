import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { IeBlocker, OAuth } from '@nswdoe/doe-ui-core'
import api from './api'

// Enhance the event system on Vue.prototype
import { useEventHub, useUpwardBroadcast, useDownwardBroadcast } from './hooks/events'
useEventHub(Vue)
useUpwardBroadcast(Vue)
useDownwardBroadcast(Vue)

const TENANT_ID = process.env.VUE_APP_AUTH_TENANT_ID
const CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID
const CALLBACK_URI = process.env.VUE_APP_AUTH_CALLBACK_URI

// https://bitbucket.det.nsw.edu.au/projects/IDM/repos/emu-frontend/browse/.env
// AMY.LYRIS@tst.det.nsw.edu.au
/* eslint no-unused-vars: "off" */
const authOptions = {
  router: router,
  pkce: true,
  // authoriseUri: 'https://sso.test.det.nsw.edu.au/sso/oauth2/authorize',
  authoriseUri: `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize`,
  tokenUri: `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/token`,
  logoutUri: `https://login.microsoftonline.com/${TENANT_ID}/oauth2/logout`,
  secureApp: false,
  forceProdMode: true,

  params: {
    client_id: CLIENT_ID,
    redirect_uri: encodeURIComponent(CALLBACK_URI),
    scope: encodeURIComponent(`${CLIENT_ID}/.default openid`),
  },
}

Vue.use(OAuth, authOptions)
const oauth = OAuth.install(Vue, authOptions)
/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)

let renderComponent, renderProps

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker
  renderProps = {
    props: {
      block: true,
      appName: 'SAIS Management Portal',
    },
  }
} else {
  // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App
  renderProps = {}

  Vue.config.productionTip = false

  /**
   * Unified interface for API requests
   * Added onto Vue.prototype object, so all Vue component instances can access it via `this.$api`
   */
  Vue.prototype.$api = api
}

new Vue({
  router,
  store,
  vuetify,
  oauth,
  render: (h) => h(renderComponent, renderProps),
}).$mount('#app')
