import { GENERIC_ERROR } from '@/constants/common'
import api from '../../api'
import _ from 'lodash'

// Vuex Suppliers module
const moduleSuppliers = {
  namespaced: true,
  state: {
    suppliers: [],
    selectedSupplier: null,
    errorMessage: null,
  },
  mutations: {
    SET_SUPPLIERS(state, suppliers) {
      state.suppliers = _.sortBy(suppliers, 'name')
    },
    SET_SUPPLIER(state, supplier) {
      state.selectedSupplier = supplier
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchSuppliers({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.suppliers.fetchSuppliers('/suppliers')
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_SUPPLIERS', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchSupplier({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.suppliers.fetchSupplier(payload.supplierId)
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_SUPPLIER', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async postSupplier({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.suppliers.postSupplier(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description || GENERIC_ERROR,
          }
        }
        return { isError: false, data: response.data }
      } catch (e) {
        return {
          isError: true,
          errorMessage: _.get(e, 'response.data.error.description') || GENERIC_ERROR,
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async putSupplier({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.suppliers.putSupplier(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        }
        return { isError: false, data: response.data }
      } catch (e) {
        return {
          isError: true,
          errorMessage: GENERIC_ERROR,
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleSuppliers
