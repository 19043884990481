import Axios from 'axios'
import UTILS from '@/store/utils'
import jwt_decode from 'jwt-decode'

const GENERIC_SERVER_ERROR =
  'A connection error has occurred. Please try again later. If this problem persists, please contact our support team.'

var store
const appWindow = UTILS.isChildWindow() ? window.opener : window

// Axios Request / Response Interceptor for handling Refresh & Auth tokens.
// Axios helpers
let isRefreshing = false
let requestsAwaitingRefresh = []

function addToRefreshWaitQueue(callback) {
  requestsAwaitingRefresh.push(callback)
}
function onRefreshed(accessToken, idToken) {
  requestsAwaitingRefresh.map((callback) => callback(accessToken, idToken))
}

// Refresh token response interceptor
Axios.interceptors.response.use(
  // return a successful response with no processing
  (response) => response,
  (error) => {
    const originalRequest = error.config
    const response = error.response

    if (!response || !originalRequest) {
      return Promise.reject(error)
    }

    const { status } = response

    // Token refresh or auth related API didn't work, so user must reauthenticate.
    if (originalRequest.url.includes('access_token')) {
      store.dispatch('reauthenticateUser')
      return Promise.reject(error)
    }

    // Check if its a 401 & invoke refreshTokens action, before retrying the request
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        store
          .dispatch('refreshTokens')
          .then((wasSuccess) => {
            if (wasSuccess) {
              isRefreshing = false
              // Refreshing complete and new token set. Re-run failed requests and empty the queue.
              onRefreshed()
              requestsAwaitingRefresh = []
            }
          })
          .catch((error) => {
            // Token refresh didn't work, so reauthenticate user
            store.dispatch('reauthenticateUser')
            throw error
          })
      }

      //Add callback to queue to rerun the 401'd request with new tokens
      return new Promise((resolve) => {
        addToRefreshWaitQueue(() => {
          const idToken = window.localStorage.getItem('idToken')
          const accessToken = window.localStorage.getItem('accessToken')
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          originalRequest.headers['x-id-token'] = idToken
          resolve(Axios(originalRequest))
        })
      })
    }

    // Should really get here... But ESLint wants this.
    return Promise.reject(error)
  }
)

export default {
  setStore(storeInstance) {
    // This is called when the store is created to give store access
    store = storeInstance
  },

  get(endpoint, noSpinner, headers, params, ignoreErrorHandling) {
    /*
      Makes a GET request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
    */
    return this.request({
      method: 'get',
      endpoint: endpoint,
      payload: null,
      noSpinner: noSpinner,
      headers: headers,
      params: params,
      ignoreErrorHandling,
    })
  },

  post(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a POST request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'post',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config,
    })
  },

  put(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a PUT request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'put',
      endpoint,
      payload,
      noSpinner,
      headers,
      ignoreErrorHandling,
      config,
    })
  },

  patch(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a PATCH request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'patch',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config,
    })
  },

  delete(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a DELETE request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'delete',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config,
    })
  },

  request({
    method,
    endpoint,
    payload,
    // noSpinner,
    headers,
    params,
    ignoreErrorHandling,
    config = {},
  }) {
    /*
      Makes a request for a specified endpoint or returns mock data if the endpoint in mocked
        method <string> - Required http verb ('get', 'post' or 'put')
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use in addition to defaults
        params <object> - Optional params to use
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an inividual request.
    */

    if (headers && headers.noDefaultHeader) {
      // don't append default headers
      delete headers.noDefaultHeader
    } else {
      headers = { ...this.getHeaders(), ...headers }
    }
    // console.log(noSpinner)

    return new Promise((resolve, reject) => {
      // if (!noSpinner) {
      //   store.dispatch('showSpinner')
      // }
      Axios({
        method,
        url: this.resolveEndpoint(endpoint),
        data: payload,
        headers,
        params,
        ignoreErrorHandling,
        ...config,
      })
        .then((response) => {
          // if (!noSpinner) {
          //   store.dispatch('hideSpinner')
          // }
          resolve(response)
        })
        .catch((error) => {
          // store.dispatch('hideSpinner')
          reject(error)
        })
    })
  },

  resolveEndpoint(endpoint) {
    // If endpoint is full url, use it unchanged...
    if (
      endpoint.indexOf('http') === 0 ||
      endpoint.indexOf('https') === 0 ||
      endpoint.indexOf('//') === 0
    ) {
      return endpoint
    }
    // If endpoint is relative (uri), prefix with our API root...
    return process.env.VUE_APP_ROOT_API + endpoint
  },

  getHeaders(allowErrors) {
    if (!allowErrors) {
      this.setupAjaxErrorHandling()
    }
    return {
      'Content-Type': 'application/json',
      'X-Correlation-ID': this.getGuid(),
      messageId: this.getGuid(),
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'x-id-token': localStorage.getItem('idToken'),
    }
  },

  getGuid() {
    // Source:
    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },

  setupAjaxErrorHandling() {
    // Globally handles ajax errors without need for "catch" methods
    // on every call.
    var me = this
    if (Axios.interceptors.response.handlers.length <= 1) {
      Axios.interceptors.response.use(
        function (response) {
          // Return successful responses unmodified...
          return response
        },
        function (error) {
          if (!error.config.ignoreErrorHandling) {
            me.showErrorWarning(error)
          }
          return Promise.reject(error)
        }
      )
    }
  },

  getErrorMessage(error) {
    // Returns a user-centric error message from an HTTP error object
    try {
      return (
        error?.response?.data?.detail ||
        error?.response?.data?.body?.description ||
        GENERIC_SERVER_ERROR
      )
    } catch (e) {
      return error.error || GENERIC_SERVER_ERROR
    }
  },

  showErrorWarning(error, httpCode) {
    // Alert all errors except 400s (bad requests) which should
    // be handled locally if necessary (apart from 403s)...
    var errorData
    try {
      errorData = error.response.data
    } catch (e) {
      errorData = {}
    }
    if (!httpCode) {
      try {
        httpCode = error.response.status
      } catch (e) {
        httpCode = ''
      }
    }

    if (httpCode === 403) {
      store.dispatch('showMessageBox', {
        icon: 'mdi-exclamation-thick',
        html: `<h2>Access Denied</h2><div>You do not have permission to use this application. Please speak to your school principal to arrange access.</div>`,
        textConfirm: 'Log out',
        onAlways() {
          location.href = '/#/logout'
          setTimeout(() => location.reload(), 500) // IE11 requires a reload after changing the location
        },
      })
    } else if (httpCode === 409) {
      // NOTE: 409 is used to prevent users to update an changed record - concurrent updates. As confirmed with the backend, 409 is reserved for concurrent updates, therefore the error message is hard-coded at the frontend.
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Unable to update</h2>Sorry this application has been updated by another user. To get the latest application information, please refresh this application.`,
      })
    } else if (httpCode === 422) {
      // NOTE: 422 is used for requests that fail server-side business logic
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>${errorData.title || 'Unable to complete request'}</h2>${
          errorData.detail || GENERIC_SERVER_ERROR
        }`,
      })
    } else if (httpCode === 404) {
      // NOTE: 400 is used for requests that fail to retrieve resources
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>${errorData.title || 'Not found'}</h2>${
          errorData.detail || GENERIC_SERVER_ERROR
        }`,
      })
    } else if (httpCode < 400 || httpCode >= 500) {
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Unable to complete request</h2>${this.getErrorMessage(error)}`,
      })
    }
  },

  getToken(tokenName) {
    try {
      let token = appWindow.localStorage.getItem(tokenName)
      return jwt_decode(token)
    } catch (e) {
      return null
    }
  },

  clearTokens() {
    'access_token,id_token,refresh_token,token_type,token_expiry'
      .split(',')
      .forEach((val) => localStorage.removeItem(val))
  },
}
