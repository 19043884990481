import { get, put } from '../http'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'

/* eslint no-console: "off" */
const fetchSubscriptions = (params) => {
  return new Promise(function (resolve) {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) =>
          value !== undefined && (typeof value === 'string' || typeof value === 'number')
      )
    )
    const queryString = new URLSearchParams(filteredParams).toString()
    get(`/v1/opUI/subscriptions?${queryString}`)
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          resolve({
            isError: true,
            errorMessage: results.data.error.description,
          })
        } else if (results.status === 200 && results.data) {
          resolve(results.data)
        } else {
          resolve({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch(() => {
        resolve({
          isError: true,
          errorMessage: GENERIC_ERROR,
        })
      })
  })
}

const fetchSubscription = ({ id, supplierId }) => {
  return new Promise(function (resolve) {
    get(`/v1/opUI/subscriptions/${id}?supplierId=${supplierId}`)
      .then((results) => {
        if (results.data.error) {
          resolve({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
        } else if (results.data) {
          resolve(results.data)
        } else {
          resolve({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        resolve({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}
// const fetchSubscriptions = (url, config = {}) => get(url, config);
const putSubscription = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/subscriptions/${data.subscriptionId}`, data, config, isForm)

export default {
  fetchSubscriptions,
  fetchSubscription,
  putSubscription,
}
