import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
import { get } from 'lodash'
import { batchRequest } from 'batch-request-js/batch-request'
// Vuex SubscriptionRequests module
const moduleSubscriptionRequests = {
  namespaced: true,
  state: {
    batchDetails: {},
    subscriptionRequests: [],
    selectedSubscriptionRequest: [],
    errorMessage: [],
    lastEvaluatedKey: null, // if there are more subscription requests to fetch from the server or not
    filter: {
      status: 'Submitted',
      subscriber: null,
      supplier: null,
      dateRange: [],
      batchName: null,
    },
  },
  mutations: {
    SET_BATCH_DETAILS(state, batchDetails) {
      state.batchDetails = batchDetails
    },
    SET_SUBSCRIPTION_REQUESTS(state, subscriptionRequests) {
      state.subscriptionRequests = subscriptionRequests
    },
    SET_SUBSCRIPTION_REQUEST(state, subscriptionRequest) {
      state.selectedSubscriptionRequest = subscriptionRequest
    },
    SET_LAST_EVALUATED_KEY(state, lastEvaluatedKey) {
      state.lastEvaluatedKey = lastEvaluatedKey
    },
    UPDATE_SUBSCRIPTION_REQUESTS(state, subscriptionRequest) {
      state.subscriptionRequests = state.subscriptionRequests.map((item) => {
        if (item.subscriptionReqId !== subscriptionRequest.subscriptionReqId) return item
        return subscriptionRequest
      })
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchSubscriptionRequests({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_LAST_EVALUATED_KEY', null)
      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.subscriptionRequests.fetchSubscriptionRequests(payload)
        if (response.isError) {
          commit('SET_ERROR_MESSAGE', response.errorMessage)
        } else {
          const subscriptionRequests = response?.subscriptionRequests || []
          if (response?.lastEvaluatedKey && subscriptionRequests?.length >= 499) {
            commit('SET_LAST_EVALUATED_KEY', response?.lastEvaluatedKey)
          }
          commit('SET_SUBSCRIPTION_REQUESTS', subscriptionRequests)
        }
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchSubscriptionRequest({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.subscriptionRequests.fetchSubscriptionRequest({
          requestId: payload.requestId,
          supplierId: payload.supplierId,
        })
        if (response) {
          commit('SET_SUBSCRIPTION_REQUEST', response)
        }
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async putSubscriptionRequests({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.subscriptionRequests.putSubscriptionRequests(payload)

        if (response.isError) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        }

        const updatedSubscriptionRequest = {
          ...response.data,
          subscriber: `${response.data.schoolCode} - ${
            rootState.moduleSchools.schools.find(
              (school) => school?.orgUnitCode === response.data.schoolCode
            )?.orgUnitName || ''
          }`,
        }
        commit('UPDATE_SUBSCRIPTION_REQUESTS', updatedSubscriptionRequest)

        return {
          isError: false,
          errorMessage: '',
        }
      } catch (error) {
        const errorCode = get(error, 'response.data.error.code', null)
        const errorMessage = get(error, 'response.data.error.description', GENERIC_ERROR)
        const overlappingSubs = get(error, 'response.data.error.data.overlapSubs', [])
        return {
          isError: true,
          errorMessage,
          errorCode,
          overlappingSubs,
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async bulkUpdate({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)
      const CHUNK_SIZE = 100
      const TOTAL_REQUESTS = payload.subscriptionReqs.length
      try {
        const chunks = []
        for (let i = 0; i < TOTAL_REQUESTS; i += CHUNK_SIZE) {
          chunks.push({
            rejectReason: payload.rejectReason,
            status: payload.status,
            subscriptionReqs: payload.subscriptionReqs.slice(i, i + CHUNK_SIZE),
          })
        }

        const bulkRequests = (data) => {
          return api.subscriptionRequests.putBulk(data)
        }

        await batchRequest(chunks, bulkRequests, { batchSize: 1, delay: 800 })

        return {
          isError: false,
          errorMessage: '',
        }
      } catch (error) {
        return {
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchBatchDetails({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.subscriptionRequests.fetchBatchDetails(payload)

        if (response.isError) {
          commit('SET_ERROR_MESSAGE', response.errorMessage)
        } else {
          commit('SET_BATCH_DETAILS', response)
        }
        return response
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleSubscriptionRequests
