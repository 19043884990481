import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '@/views/About'
import NotFound from '@/views/common/NotFound'
import API from '@/store/apiUtils'
import authGuard from './authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    beforeEnter: authGuard,
  },
  {
    path: '/',
    name: 'about',
    component: About,
    beforeEnter: authGuard,
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: About,
    beforeEnter: authGuard,
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/common/Error.vue'),
  },
  {
    path: '/serviceregistry/servicesuppliers',
    name: 'servicesuppliers',
    component: () => import('../views/ServiceSuppliers'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/servicesuppliers/add',
    name: 'addservicesuppliers',
    component: () => import('../views/ServiceSuppliers/Add/Add.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/servicesuppliers/view/:id',
    name: 'viewservicesuppliers',
    component: () => import('../views/ServiceSuppliers/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/products',
    name: 'products',
    component: () => import('../views/Products'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/products/add',
    name: 'addproducts',
    component: () => import('../views/Products/Add/Add.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/products/view/:productId/:supplierId',
    name: 'viewproducts',
    component: () => import('../views/Products/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/services',
    name: 'services',
    component: () => import('../views/Services'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/services/add',
    name: 'addservices',
    component: () => import('../views/Services/Add/Add.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/services/view/:id',
    name: 'viewservices',
    component: () => import('../views/Services/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/instances',
    name: 'instances',
    component: () => import('../views/Instances'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/instances/view/:supplierId',
    name: 'viewinstances',
    component: () => import('../views/Instances/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/subscriptions',
    name: 'subscriptions',
    component: () => import('../views/Subscriptions'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/subscriptions/view/:id',
    name: 'viewsubscriptions',
    component: () => import('../views/Subscriptions/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/subscriptionrequests',
    name: 'subscriptionrequests',
    component: () => import('../views/SubscriptionRequests'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/subscriptionrequests/view/:id',
    name: 'viewsubscriptionrequest',
    component: () => import('../views/SubscriptionRequests/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/batch-request/view/:batchId',
    name: 'viewbatchrequest',
    component: () => import('../views/SubscriptionRequests/View/ViewBatchStats.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/supplierusers',
    name: 'supplierusers',
    component: () => import('../views/SupplierUsers'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/supplierusers/add',
    name: 'addsupplierusers',
    component: () => import('../views/SupplierUsers/Add/Add.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/serviceregistry/supplierusers/view/:id',
    name: 'viewsupplierusers',
    component: () => import('../views/SupplierUsers/View/View.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/logout',
    beforeEnter(to, from, next) {
      const TENANT_ID = process.env.VUE_APP_AUTH_TENANT_ID
      window.location.href = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/logout`

      // Remove the auth tokens in the next engine cycle rather than
      // immediately to prevent a race condition with `reauthenticateSession()`
      // in <AppSessionCountdownBar/>
      setTimeout(API.clearTokens, 0)

      // Make absolutely sure the router doesn't proceed from here.
      next(false)
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
