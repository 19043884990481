import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
import { get } from 'lodash'

// Vuex Services module
const moduleServices = {
  namespaced: true,
  state: {
    services: [],
    selectedService: null,
    errorMessage: null,
  },
  mutations: {
    SET_SERVICES(state, services) {
      state.services = services
    },
    SET_SERVICE(state, service) {
      state.selectedService = service
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchServices({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.services.fetchServices({
          limit: payload.limit,
          page: payload.page,
        })
        if (response?.isError) commit('SET_ERROR_MESSAGE', response?.errorMessage)
        else commit('SET_SERVICES', response)
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) commit('SET_IS_LOADING', false, { root: true })
      }
    },
    async fetchService({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.services.fetchService(payload)
        if (response?.isError) commit('SET_ERROR_MESSAGE', response?.errorMessage)
        else commit('SET_SERVICE', response)
      } catch (error) {
        commit('SET_ERROR_MESSAGE', get(error, 'response.data.error.description', GENERIC_ERROR))
      } finally {
        if (rootState.isLoading) commit('SET_IS_LOADING', false, { root: true })
      }
    },
    async postService({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.services.postServices(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          return { isError: false }
        }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async putService({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.services.putServices(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          return { isError: false }
        }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleServices
