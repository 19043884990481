import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'
// Vuex Instances module
const moduleInstances = {
  namespaced: true,
  state: {
    instanceSecrets: {},
    instances: [],
    selectedInstance: [],
    errorMessage: null,
  },
  mutations: {
    SET_INSTANCES(state, instances) {
      state.instances = instances
    },
    SET_INSTANCE_SECRETS(state, instanceSecrets) {
      state.instanceSecrets = instanceSecrets
    },
    SET_INSTANCE(state, instance) {
      state.selectedInstance = instance
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchInstances({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.instances.fetchInstances('/instances')

        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_INSTANCES', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchInstanceSecrets({ commit, rootState }, supplierId) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.instances.fetchInstanceSecrets(supplierId)

        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_INSTANCE_SECRETS', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async postInstance({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }
      try {
        const response = await api.instances.postInstance(payload)

        if ((response && response.data.error) || !response.data.clientId) {
          commit('SET_ERROR_MESSAGE', response.data.error.description || GENERIC_ERROR)
          return {
            isError: true,
            errorMessage: response.data.error.description || GENERIC_ERROR,
          }
        } else {
          const updatedInstances = rootState.moduleInstances?.instances.map((instance) => {
            if (instance.supplierId !== payload.supplierId) return instance
            return {
              ...instance,
              clientId: response.data.clientId,
            }
          })
          commit('SET_INSTANCES', updatedInstances)
          return { isError: false, data: response.data }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', _.get(e, 'response.data.error.description', GENERIC_ERROR))
        return {
          isError: true,
          errorMessage: GENERIC_ERROR,
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleInstances
