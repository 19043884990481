import { get } from 'lodash'
import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
// Vuex Subscriptions module
const moduleSubscriptions = {
  namespaced: true,
  state: {
    subscriptions: [],
    selectedSubscription: [],
    errorMessage: null,
    lastEvaluatedKey: null, // if there are more subscription requests to fetch from the server or not
    filter: {
      status: 'Activated',
      subscriber: null,
      supplier: null,
      dateRange: [],
      batchName: null,
    },
  },
  mutations: {
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions
    },
    SET_SUBSCRIPTION(state, subscription) {
      state.selectedSubscription = subscription
    },
    SET_LAST_EVALUATED_KEY(state, lastEvaluatedKey) {
      state.lastEvaluatedKey = lastEvaluatedKey
    },
    UPDATE_SUBSCRIPTIONS(state, subscription) {
      state.subscriptions = state.subscriptions.map((item) => {
        if (item.subscriptionId !== subscription.subscriptionId) return item
        return subscription
      })
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchSubscriptions({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }
      commit('SET_ERROR_MESSAGE', null)
      commit('SET_LAST_EVALUATED_KEY', null)

      try {
        const response = await api.subscriptions.fetchSubscriptions(payload)
        if (response.isError) {
          commit('SET_ERROR_MESSAGE', response.errorMessage)
        } else {
          const subscriptionList = response?.subscriptions || []

          if (response?.lastEvaluatedKey && subscriptionList?.length >= 499) {
            commit('SET_LAST_EVALUATED_KEY', response?.lastEvaluatedKey)
          }
          commit('SET_SUBSCRIPTIONS', subscriptionList)
        }
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async fetchSubscription({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }
      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.subscriptions.fetchSubscription({
          id: payload.id,
          supplierId: payload.supplierId,
        })
        if (response.isError) {
          commit('SET_ERROR_MESSAGE', response.errorMessage)
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          commit('SET_SUBSCRIPTION', response)
          return {
            isError: false,
            errorMessage: '',
          }
        }
      } catch (error) {
        commit('SET_ERROR_MESSAGE', GENERIC_ERROR)
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
    async putSubscription({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }
      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.subscriptions.putSubscription(payload)
        if (response.isError) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        }

        const updatedSubscription = {
          ...response.data,
          subscriber: `${response.data.schoolCode} - ${
            rootState.moduleSchools.schools.find(
              (school) => school?.orgUnitCode === response.data.schoolCode
            )?.orgUnitName || ''
          }`,
        }

        commit('UPDATE_SUBSCRIPTIONS', updatedSubscription)

        return {
          isError: false,
          errorMessage: '',
        }
      } catch (error) {
        return {
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleSubscriptions
