import { get, post, put } from '../http'
import { GENERIC_ERROR } from '@/constants/common'

/* eslint no-console: "off" */
const fetchSupplierUsers = () => {
  return new Promise((done) => {
    get('/v1/opUI/supplierUsers')
      .then((results) => {
        if (Array.isArray(results.data)) done(results.data)

        done({
          isError: true,
          errorMessage: GENERIC_ERROR,
        })
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}

/* eslint no-console: "off" */
const fetchSupplierUser = ({ userId, supplierId }) => {
  return new Promise((done) => {
    get(`/v1/opUI/supplierUsers/${userId}?supplierId=${supplierId}`)
      .then((results) => {
        if (results.data.id) {
          done(results.data)
        } else {
          done({
            isError: true,
            errorMessage: get(results, 'data.description', GENERIC_ERROR),
          })
        }
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}
const postSupplierUser = (data = {}, config = {}, isForm = false) =>
  post(`/v1/opUI/admin/supplierUsers/supplierUser`, data, config, isForm)

const putSupplierUser = (data = {}, config = {}, isForm = false) =>
  put(`/v1/opUI/admin/supplierUsers/${data.id}`, data, config, isForm)

export default {
  fetchSupplierUsers,
  fetchSupplierUser,
  postSupplierUser,
  putSupplierUser,
}
