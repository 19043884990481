import axios from 'axios'
import qs from 'qs'

const TIMEOUT = 60 * 1000 * 2 // 2 min

/**
 * Create an axios instance and configure THIS instance.
 * If directly import axios and use, settings here won't take effect.
 * Default headers is 'Content-Type': 'application/json'
 */
const requestConfig = {
  // API base URL is configurable and set up in .env.* files
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // 2 min
  timeout: TIMEOUT,
  timeoutErrorMessage: 'Request timeout',
}
const axiosInstance = axios.create(requestConfig)

/**
 * GET method
 * @param url
 * @param config
 * @returns {Promise}
 */
export function get(url, config = {}) {
  return axiosInstance.get(url, config)
}

/**
 * GET method
 * @param url
 * @returns {Promise}
 */
export function getUrl(url) {
  const axiosInstance1 = axios.create({
    timeout: TIMEOUT,
  })
  return axiosInstance1.get(url)
}

/**
 * POST method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function post(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.transformRequest = (data) => qs.stringify(data)
  } else {
    // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json'
    axiosInstance.defaults.transformRequest = undefined
  }
  return axiosInstance.post(url, JSON.stringify(data), config)
}

/**
 * PUT method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function put(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.transformRequest = (data) => qs.stringify(data)
  } else {
    // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json'
    axiosInstance.defaults.transformRequest = undefined
  }
  return axiosInstance.put(url, JSON.stringify(data), config)
}

function getGuid() {
  // Source:
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// Can add JWT token here for each request
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers['x-id-token'] = localStorage.getItem('access_token')
    config.headers['messageId'] = getGuid()
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// axiosInstance.interceptors.response.use(
//   function (response) {
//     if (response.status === 200 && response.data.error) {
//       console.log(this)//not able to access store
//     }
//     return response
//   },
//   function (error) {
//     return Promise.reject(error)
//   },
// )
//
// export default axiosInstance
