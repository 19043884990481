const authModule = {
  state: {
    isAuthenticated: false,
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
  },
  mutations: {
    SET_AUTHENTICATED(state, authenticated) {
      state.isAuthenticated = authenticated;
    }
  },
  actions: {}
}

export default authModule;