<template>
  <v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
    <div v-if="isAuthenticated">
      <NavigationDrawer :items="navDrawerItems" />

      <AppBar
        color="primary"
        appName="my-nsw-gov"
        title="SAIS Operation UI Portal"
        :showNavIcon="true"
        :showNotifications="false"
        :enableHomeLink="false"
      >
        <template v-slot:appIcon>
          <AppIcon
            appName="my-nsw-gov"
            :transparent="true"
            size="50px"
            class="mt-2"
          />
        </template>

        <template v-slot:profile>
          <Profile
            :givenName="givenName"
            :surname="surname"
            :updateProfile="false"
            :changePassword="false"
            :updateSecretQuestion="false"
            :portalSettings="false"
            :logout="true"
            :logoutHandler="logout"
          />
        </template>
      </AppBar>

      <v-main>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>

      <!-- App-wide component -->
      <BlockUI v-if="$store.state.isLoading" />
    </div>
  </v-app>
</template>

<script>
import { AppBar, NavigationDrawer, Profile, AppIcon, BlockUI } from '@nswdoe/doe-ui-core'
import API from '@/store/apiUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    AppIcon,
  },
  mounted() {},
  data() {
    return {
      navDrawerItems: [
        {
          title: 'Home',
          icon: 'mdi-home',
          route: '/home',
        },
        {
          title: 'Suppliers',
          icon: 'mdi-account-group',
          route: '/serviceregistry/servicesuppliers',
        },
        {
          title: 'Supplier Products',
          icon: 'mdi-package-variant-closed',
          route: '/serviceregistry/products',
        },
        {
          title: 'Subcategories',
          icon: 'mdi-cogs',
          route: '/serviceregistry/services',
        },
        {
          title: 'Client Instances',
          icon: 'mdi-desktop-classic',
          route: '/serviceregistry/instances',
        },
        {
          title: 'Subscription Requests',
          icon: 'mdi-briefcase-search',
          route: '/serviceregistry/subscriptionrequests',
        },
        {
          title: 'Subscriptions',
          icon: 'mdi-briefcase-check',
          route: '/serviceregistry/subscriptions',
        },
        {
          title: 'Supplier Users',
          icon: 'mdi-account-supervisor',
          route: '/serviceregistry/supplierusers',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'givenName', 'surname']),
  },
  updated() {},
  methods: {
    logout() {
      const TENANT_ID = process.env.VUE_APP_AUTH_TENANT_ID
      window.location.href = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/logout`

      setTimeout(API.clearTokens, 0)
    },
  },
}
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import './scss/ads';

// example of overriding classes in `ads.scss`
.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  /*  a:not(.v-btn, .v-tab) {
      color: $ads-blue-1;

      &:visited {
        color: $ads-blue-1;
      }
    }*/
}

.container {
  padding: 12px 30px;

  .container {
    padding: 0;
  }
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}
</style>
