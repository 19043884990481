import api from '../../api'
import { GENERIC_ERROR } from '@/constants/common'
import { get } from 'lodash'

// Vuex Products module
const moduleProducts = {
  namespaced: true,
  state: {
    products: [],
    selectedProduct: [],
    errorMessage: null,
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_PRODUCT(state, product) {
      state.selectedProduct = product
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
  },
  actions: {
    async fetchProducts({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.products.fetchProducts('/products')
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_PRODUCTS', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', get(e, 'response.data.error.description', GENERIC_ERROR))
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async fetchProduct({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      commit('SET_ERROR_MESSAGE', null)

      try {
        const response = await api.products.fetchProduct(payload)
        if (response) {
          if (response.isError) {
            commit('SET_ERROR_MESSAGE', response.errorMessage)
          } else {
            commit('SET_PRODUCT', response)
          }
        }
      } catch (e) {
        commit('SET_ERROR_MESSAGE', get(e, 'response.data.error.description', GENERIC_ERROR))
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async postProduct({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.products.postProduct(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          return { isError: false }
        }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },

    async putProduct({ commit, rootState }, payload) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true })
      }

      try {
        const response = await api.products.putProduct(payload)
        if (response && response.data.error) {
          return {
            isError: true,
            errorMessage: response.data.error.description,
          }
        } else {
          return { isError: false }
        }
      } catch (e) {
        return {
          isError: true,
          errorMessage: get(e, 'response.data.error.description', GENERIC_ERROR),
        }
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true })
        }
      }
    },
  },
}

export default moduleProducts
