<template>
  <div id="not-found__content">
    <Header />

    <div>
      <div class="error-container pa-5 pb-9 text-center">
        <div class="icon-container">
          <v-img
            class="error-icon"
            alt="404-error"
            width="100px"
            :src="imageUrl"
          />
        </div>
        <div>
          <h3 class="pb-sm-8 pb-4">The page you are looking for does not exist.</h3>
          <p class="mb-7">
            If you continue to experience this error please contact our Support Team
          </p>
          <v-btn
            class="primary elevation-0"
            color="primary"
            to="/"
          >
            Let's go back
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header'
import imageUrl from '@/assets/svg/ads-404-icon.svg'
export default {
  name: 'NotFound',
  components: {
    Header,
  },
  data() {
    return {
      imageUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-container {
  padding-top: 40px;
}

.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: $ads-navy !important;
  }
  h3 {
    font-size: 1.5rem;
    color: $ads-navy !important;
  }
  .icon-container {
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 30px;
      margin: auto;
    }
  }
}
</style>
