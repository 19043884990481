import { post, get } from '../http'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'

const fetchSifObjects = () => {
  return new Promise(function (done) {
    get(`/v1/opUI/admin/sifObjects`)
      .then((results) => {
        if (results.data.sifObjects) done(results.data.sifObjects)
        else
          done({
            isError: true,
            errorMessage: _.get(results, 'data.error.description', GENERIC_ERROR),
          })
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}

const fetchSifObject = () => {
  return new Promise(function (resolve) {
    resolve({})
  })
}
const postSifObjects = (url, data = {}, config = {}, isForm = false) =>
  post(url, data, config, isForm)

export default {
  fetchSifObjects,
  fetchSifObject,
  postSifObjects,
}
