import { post, get } from '../http'
import { GENERIC_ERROR } from '@/constants/common'
import _ from 'lodash'

/* eslint no-console: "off" */
const fetchInstances = () => {
  return new Promise((done) => {
    get('/v1/opUI/clients')
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && results.data.clients) {
          done(results.data.clients)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: _.get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}

export const fetchInstanceSecrets = (supplierId) => {
  return new Promise((done) => {
    const url = supplierId ? `/v1/opUI/secrets?supplierId=${supplierId}` : '/v1/opUI/secrets'

    get(url)
      .then((results) => {
        if (results.status === 200 && results.data.error) {
          done({ isError: true, errorMessage: results.data.error.description })
        } else if (results.status === 200 && results.data) {
          done(results.data)
        } else {
          done({
            isError: true,
            errorMessage: GENERIC_ERROR,
          })
        }
      })
      .catch((error) => {
        done({
          isError: true,
          errorMessage: _.get(error, 'response.data.error.description', GENERIC_ERROR),
        })
      })
  })
}

const postInstance = (data = {}, config = {}, isForm = false) =>
  post('/v1/opUI/admin/clients/client', data, config, isForm)

export default {
  fetchInstances,
  fetchInstanceSecrets,
  postInstance,
}
