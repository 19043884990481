import { isObject } from 'lodash'

export default {
  log(message) {
    // Logs a console message for dev environments and takes care of disabling linting
    if (process.env.NODE_ENV === 'development') {
      /*eslint-disable */
      console.log(message)
      /*eslint-enable */
    }
  },

  isArray(value) {
    return value !== null && typeof value === 'object' && value.length !== undefined
  },

  isObject(value) {
    return value !== null && typeof value === 'object'
  },

  isEmpty(value) {
    // Note that a value containing only spaces is counted as empty
    return (
      value === null ||
      value === undefined ||
      String(value).trim() === '' ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    )
  },

  clone(obj) {
    var clone = JSON.stringify(obj)
    return JSON.parse(clone)
  },

  isChildWindow() {
    try {
      return window.opener.location.hostname === window.location.hostname
    } catch (e) {
      return false
    }
  },
  objectToQueryString({
    object = null,
    allowedKeys = null,
    addQuestionMark = true,
    defaulQuery = '',
  }) {
    if (!isObject(object) || !Object.keys(object).length) return ''

    let queryString = Object.keys(object)

    queryString = queryString
      .filter((key) => {
        if (allowedKeys && allowedKeys.length) return object[key] && allowedKeys.includes(key)

        return !!object[key]
      })
      .map((key) => `${key}=${object[key]}`)
      .join('&')

    if (addQuestionMark && queryString) {
      queryString = `?${queryString}`
    }

    if (defaulQuery && !queryString) {
      queryString = defaulQuery
    }

    return queryString
  },
}
