import { getUrl } from '../http'

/* eslint no-console: "off" */
const fetchSchools = () => {
  return new Promise((done, reject) => {
    getUrl('https://refdata.enrol.education.nsw.gov.au/all-schools.json')
      .then((schools) => {
        const nswSchools = schools.data.filter((s) => s.stateCode === 'NSW' && s.activeInd === 'A')
        done(nswSchools)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default {
  fetchSchools,
}
