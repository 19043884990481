import UTILS from '@/store/utils'

export default {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  set(state, [key, value]) {
    if (state) {
      try {
        eval(`state.${key} = value`)
      } catch (e) {
        UTILS.log(`FAILED TO SET STATE: ${key} = "${value}"`)
      }
    }
  },
}
