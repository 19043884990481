export default {
  // COMMON
  userName: '', // User name supplied from auth tokens
  givenName: '',
  surname: '',
  userGroup: [], // User group
  userID: null, // got the info from the id token and used to save previously selected school in the local storage
  isAdmin: false,
  isReadOnlyUser: false,
  schools: [], //the schools that are accessible to logged in users.
  isLoading: false,
  errorMessage: '',
}
